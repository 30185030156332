import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

@Injectable()
export class MockAuthenticationService {
  authenticated = false;
  url: string | null = null;

  isAuthenticated(): boolean {
    return this.authenticated;
  }

  /**
   * Authenticates the user.
   * @param route Current activeted route snapshot.
   */
  login(url: string): void {
    this.url = url;
  }

  /**
   * Logs out the user and clear credentials.
   */

  handleLogin(): Observable<boolean> {
    return of(false);
  }
}
