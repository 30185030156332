<div class="modal-alert">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Attenzione</h4>
    <a type="button" aria-label="Close" (click)="closeModal()">
      <i class="bi bi-x-lg icona-sm icona-secondary"></i>
    </a>
  </div>
  <div class="modal-body d-flex">
    <div class="col-sm-8 col-12 d-flex align-items-center">
      <p>{{ messagge }}</p>
    </div>
    <div class="col-4 d-none d-sm-flex align-items-center">
      <div class="icona bg-orange-svg w-100 d-flex justify-content-center">
        <i class="bi bi-exclamation-triangle icona-lg"></i>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <a type="button" class="btn btn-primary" (click)="closeModal()"> VAI </a>
  </div>
</div>
