import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CredentialsService } from '@app/auth';
import { Observable, concat, filter, map, mergeMap, of } from 'rxjs';
import { CacheStorageService } from '../cache-storage.service';
import { Credenziali } from '@app/modello/credenziali';

@Injectable()
export class CacheStorageInterceptor implements HttpInterceptor {
  private user: Credenziali | null = null;

  constructor(private credentialsService: CredentialsService, private cacheStorageService: CacheStorageService) {
    this.credentialsService.getCredentials().subscribe((credentials) => {
      if (credentials?.tax_code !== this.user?.tax_code) {
        if (credentials?.tax_code) {
          // cancello la cache perchè l'utente ha fatto login
          this.cacheStorageService.deleteCache(credentials.tax_code);
        } else {
          // cancello la cache perchè l'utente ha fatto logout
          this.cacheStorageService.deleteCache(this.user!.tax_code);
        }
      }
      this.user = credentials;
    });
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const codiceFiscale = this.credentialsService.getCodiceFiscale();
    if (codiceFiscale && request.method === 'GET' && request.url.startsWith('/api')) {
      return concat(
        this.cacheStorageService.getCachedResponse(request.urlWithParams, { cacheName: codiceFiscale }),
        next.handle(request).pipe(
          filter((response) => response instanceof HttpResponse),
          mergeMap((response: HttpEvent<unknown>) => {
            if (response instanceof HttpResponse) {
              if (
                response.status >= 200 &&
                response.status < 300 &&
                response.headers.get('Content-Type') === 'application/json'
              ) {
                return this.cacheStorageService.cacheResponse(codiceFiscale, request.urlWithParams, response);
              } else if (response.status < 500) {
                return this.cacheStorageService
                  .deleteCacheItem(codiceFiscale, request.urlWithParams)
                  .pipe(map(() => response));
              } else {
                return of(response);
              }
            }
            return of(response);
          })
        )
      );
    }
    return next.handle(request);
  }
}
