import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Constants } from '@app/@shared/constants';
import { IPatientStatus } from '@app/modello/screening/patient-status';
import { environment } from '@env/environment';
import { Observable, of, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PatientStatusService {
  constructor(private http: HttpClient) {}

  find(): Observable<IPatientStatus> {
    const patientStatusCache = sessionStorage.getItem(Constants.Keys.Screening.STATO_PAZIENTE);
    if (patientStatusCache) {
      return of(JSON.parse(patientStatusCache));
    }
    return this.http.get<IPatientStatus>(`${environment.screeningServerUrl}/patient-status`).pipe(
      tap((response) => {
        sessionStorage.setItem(Constants.Keys.Screening.STATO_PAZIENTE, JSON.stringify(response));
      })
    );
  }
}
