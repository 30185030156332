import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, Observable, catchError, from, map, mergeMap, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CacheStorageService {
  private fromAngularResponseHeaders(headers: HttpHeaders): HeadersInit | undefined {
    return headers.keys().map((key) => [key, headers.get(key)!]);
  }

  private toAngularResponseHeaders(response: Response): HttpHeaders {
    const headers = new HttpHeaders();
    response.headers.forEach((value, key) => {
      headers.append(key, value);
    });
    return headers;
  }

  getCachedResponse(key: string, options?: MultiCacheQueryOptions): Observable<HttpResponse<any>> {
    const cachedResponse: Observable<HttpResponse<unknown>> = EMPTY;
    if (caches) {
      return from(caches.match(key, options)).pipe(
        mergeMap((response) => {
          if (response) {
            return from(response.json()).pipe(
              map(
                (body) =>
                  new HttpResponse({
                    body,
                    headers: this.toAngularResponseHeaders(response),
                    status: response.status,
                    statusText: response.statusText,
                    url: response.url,
                  })
              )
            );
          }
          return EMPTY;
        })
      );
    }
    return cachedResponse;
  }

  cacheResponse(cacheName, cacheKey, response): Observable<HttpResponse<unknown>> {
    const cachedResponse: Observable<HttpResponse<unknown>> = EMPTY;
    if (caches) {
      return from(caches.open(cacheName)).pipe(
        map((namedCache) => {
          const body = response.body ? JSON.stringify(response.body) : null;
          namedCache.put(
            cacheKey,
            new Response(body, {
              status: response.status,
              headers: this.fromAngularResponseHeaders(response.headers),
              statusText: response.statusText,
            })
          );
          return response;
        }),
        catchError(() => of(response))
      );
    }
    return cachedResponse;
  }

  deleteCacheItem(cacheName, cacheKey): Observable<boolean> {
    if (caches) {
      return from(caches.open(cacheName)).pipe(
        map((namedCache) => {
          namedCache.delete(cacheKey);
          return true;
        }),
        catchError(() => of(false))
      );
    }
    return of(false);
  }

  deleteCache(cacheName): Observable<boolean> {
    if (caches) {
      return from(caches.delete(cacheName));
    }
    return of(false);
  }
}
