import { Injectable } from '@angular/core';
import { Constants } from '@app/@shared/constants';
import { IPersona } from '@app/modello/persona';
import { BehaviorSubject, Observable, shareReplay } from 'rxjs';

export interface StorageChange {
  key: string;
  value: string;
  storageArea: 'localStorage' | 'sessionStorage';
}

export interface StorageGetItem {
  key: string;
  storageArea: 'localStorage' | 'sessionStorage';
}

@Injectable({
  providedIn: 'root',
})
export class DeleganteServiceService {
  public delegante$: Observable<IPersona | null>;
  private storageChange$: BehaviorSubject<IPersona | null> = new BehaviorSubject<IPersona | null>(null);

  constructor() {
    this.storageChange$.next(JSON.parse(sessionStorage.getItem(Constants.Keys.Fse.DELEGANTE)!));
    this.delegante$ = this.storageChange$.pipe(shareReplay(1));
  }

  getDelegante(): IPersona | null {
    return this.storageChange$.value!;
  }

  setDelegante(delegante: IPersona | null): void {
    sessionStorage.setItem(Constants.Keys.Fse.DELEGANTE, JSON.stringify(delegante));
    this.storageChange$.next(delegante);
  }
}
