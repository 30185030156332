import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnDestroy } from '@angular/core';
import { ProfileService } from '@app/@shared/profiles/profile.service';
import { map, Observable } from 'rxjs';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
})
export class NotFoundComponent implements OnDestroy {
  saluteBasilicataUrl$?: Observable<string | undefined>;

  constructor(@Inject(DOCUMENT) private doc: Document, profileService: ProfileService) {
    this.addLinkForCanonicalURL();
    this.saluteBasilicataUrl$ = profileService.getProfileInfo().pipe(map((profilo) => profilo.indirizzoPortale));
  }

  private addLinkForCanonicalURL(): void {
    const link: HTMLLinkElement = this.doc.createElement('link');
    link.setAttribute('rel', 'canonical');
    this.doc.head.appendChild(link);
    link.setAttribute('href', window.location.origin + '/404');
    link.setAttribute('id', 'canonicalUrl404');
  }

  private removeLinkForCanonicalURL(): void {
    const oldUrl = this.doc.getElementById('canonicalUrl404');
    if (oldUrl) {
      oldUrl.remove();
    }
  }

  ngOnDestroy(): void {
    this.removeLinkForCanonicalURL();
  }
}
