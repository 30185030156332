import { Credenziali } from '@app/modello/credenziali';
import { Observable, of } from 'rxjs';

export class MockCredentialsService {
  credentials: Credenziali | null = {
    name: 'test',
    avatar: '',
    email: 'test@test.example',
    email_verified: false,
    family_name: 'doe',
    given_name: 'john',
    preferred_username: 'test',
    tax_code: 'asdafasdfasdfsfd',
    resource_access: null,
    auth_method: '',
  };

  getCredentials(): Observable<Credenziali | null> {
    return of(this.credentials);
  }
}
