import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { catchError, finalize, first, interval, map, Observable, of } from 'rxjs';
import { ConsensoService } from './consenso.service';
import { AuthenticationGuard } from '@app/auth';
import { Constants } from '@app/@shared/constants';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ModalFseAttenzioneComponent } from './modal-fse-attenzione/modal-fse-attenzione.component';
import { IConsensiFse } from '@app/modello/fse/consenso';
import { ModalErroreGuardiaComponent } from '@app/@shared/modal-errore/modal-errore.component';
import { ProfileService } from '@app/@shared/profiles/profile.service';

@Injectable({
  providedIn: 'root',
})
export class ConsensoGuard implements CanActivate {
  public modalRef: NgbModalRef | null = null;
  consenso: IConsensiFse | null = null;
  public indirizzoPortale?: string;

  constructor(
    private consensoService: ConsensoService,
    private authenticatinoGuard: AuthenticationGuard,
    private router: Router,
    private modalService: NgbModal,
    private servizioInfo: ProfileService
  ) {
    this.servizioInfo.getProfileInfo().subscribe((info) => (this.indirizzoPortale = info.indirizzoPortale));
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.authenticatinoGuard.canActivate(route, state)) {
      let modalRefLoader: NgbModalRef | null = null;
      let consensoGuardCompleted = false;
      const consensoFse$ = this.consensoService.getConsensi().pipe(
        finalize(() => {
          consensoGuardCompleted = true;
          modalRefLoader?.dismiss();
        }),
        map((consenso) => {
          if (!consenso.valido) {
            this.openModale();
            this.router.navigateByUrl('/area-personale/gestisci-consensi/consensi-fse', {
              replaceUrl: true,
            });
            return false;
          }
          return true;
        }),
        catchError((e) => {
          const modalRef = this.modalService.open(ModalErroreGuardiaComponent, { centered: true, size: 'lg' });
          modalRef.componentInstance.title = 'Attenzione';
          modalRef.componentInstance.loader = false;
          if (e?.status === 404) {
            modalRef.componentInstance.message =
              "Impossibile accedere al Fascicolo Sanitario Elettronico della Regione Basilicata. L'utente inserito non risulta residente presso la Regione Basilicata.";
            sessionStorage.setItem(Constants.Keys.Fse.UTENTE_NON_CENSITO, 'true');
          } else {
            sessionStorage.setItem(Constants.Keys.Fse.UTENTE_NON_CENSITO, 'false');
            modalRef.componentInstance.message = `Spiacenti, non è stato possibile completare l'operazione! Si prega di riprovare più tardi. Se il problema persiste si invita a rivolgersi all’assistenza <a href="${this.indirizzoPortale}/assistenza/" target="_blank">link per proseguire su Assistenza</a>`;
          }
          return of(false);
        })
      );
      interval(200)
        .pipe(first())
        .subscribe(() => {
          if (!consensoGuardCompleted) {
            modalRefLoader = this.modalService.open(ModalErroreGuardiaComponent, {
              centered: true,
              size: 'md',
              backdrop: 'static',
              keyboard: false,
            });
            modalRefLoader.componentInstance.loader = true;
          }
        });
      return consensoFse$;
    }
    return of(false);
  }

  openModale(): void {
    this.modalRef = this.modalService.open(ModalFseAttenzioneComponent, { centered: true, size: 'lg' });
    this.modalRef.componentInstance.messagge = Constants.STRINGA_RICHIESTA_CONSENSO_FSE;
    // this.modalRef.componentInstance.redirectTo = redirectTo;
  }
}
