import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotFoundComponent } from './not-found.component';
import { ShellModule } from '@app/shell/shell.module';

@NgModule({
  declarations: [NotFoundComponent],
  imports: [CommonModule, ShellModule],
})
export class NotFoundModule {}
