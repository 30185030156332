import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-fse-attenzione',
  templateUrl: './modal-fse-attenzione.component.html',
  styleUrls: ['./modal-fse-attenzione.component.scss'],
})
export class ModalFseAttenzioneComponent {
  public messagge: string = '';
  public redirectTo: string | undefined;

  constructor(public modal: NgbActiveModal, private router: Router) {}

  closeModal(): void {
    this.modal.dismiss();
    // if (this.redirectTo) {
    //   this.router.navigate(['/fascicolo/gestisci-consensi'], {
    //     state: {
    //       redirectTo: this.redirectTo,
    //       consenso: undefined
    //     },
    //     replaceUrl: true,
    //   });
    // }
  }
}
