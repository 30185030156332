<header class="header rs-nav">
  <div class="menu-bar clearfix">
    <div class="container d-flex flex-nowrap justify-content-between align-items-center">
      <div class="menu-logo col-md-auto mt-md-0 mt-2 d-flex align-items-center">
        <a href="https://www.regione.basilicata.it/giunta/site/giunta/home.jsp" target="_blank">
          <img class="logo-regione" src="assets/header/RB_logo_fondochiaro_blu.svg" title="Regione Basilicata" />
          <img class="logotipo-regione" src="assets/header/Logotipo-regione.svg" title="Regione Basilicata" />
        </a>
        <a
          *ngIf="saluteBasilicataUrl$ | async as saluteBasilicataUrl"
          class="d-flex align-items-center justify-content-center"
          [href]="saluteBasilicataUrl"
          target="_blank"
        >
          <img src="assets/logo.svg" title="Salute Basilicata" />
          <div class="font-logo-salute">
            <h1>Salute Basilicata</h1>
            <h2>Fascicolo Sanitario Elettronico</h2>
          </div>
        </a>
      </div>
      <div class="d-flex align-items-center">
        <div *ngIf="credentials" class="user-dropdown-container align-items-center d-flex" ngbDropdown>
          <div>
            <span class="text-capitalize fst-italic fs-6 user-name d-none d-lg-block" *ngIf="accessoFacilitatoLogin">
              Accesso Facilitato
            </span>
            <span class="text-capitalize user-name d-none d-lg-block">
              {{ credentials?.given_name | lowercase }} {{ credentials?.family_name | lowercase }}
            </span>
          </div>
          <a id="user-dropdown" class="nav-link" role="button" ngbDropdownToggle>
            <img
              class="avatar"
              [src]="credentials.avatar"
              height="48"
              title="immagine profilo"
              title="Immagine profilo"
            />
          </a>
          <div ngbDropdownMenu aria-labelledby="user-dropdown" class="dropdown-menu dropdown-menu-end mx-3">
            <div class="dropdown-header d-lg-none text-capitalize user-name">
              {{ credentials?.given_name | lowercase }} {{ credentials?.family_name | lowercase }}
            </div>
            <div class="dropdown-divider d-lg-none"></div>
            <button class="dropdown-item" (click)="logout()">
              <i class="bi bi-box-arrow-right icona-xs me-3"></i>Esci
            </button>
          </div>
        </div>
        <a href="https://www.fascicolosanitario.gov.it/" target="_blank">
          <img
            class="ms-3 my-1 logo-fse"
            src="assets/header/Logo-FSE-nazionale.svg"
            title="Fascicolo Sanitario Elettronico"
            alt="Fascicolo Sanitario Elettronico"
          />
          <img
            class="ms-3 my-1 logotipo-fse"
            src="assets/header/Logotipo-Fse.svg"
            alt="Fascicolo Sanitario Elettronico"
            title="Fascicolo Sanitario Elettronico"
          />
        </a>
      </div>
    </div>
  </div>
</header>
