import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CredentialsService } from '@app/auth';
import { IAssistito } from '@app/modello/assistito';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HomeService {
  constructor(private http: HttpClient, private credentialsService: CredentialsService, private router: Router) {}

  getAnagrafeAssistito(): Observable<HttpResponse<IAssistito>> {
    return this.http.get<IAssistito>(`${environment.anagrafeServerUrl}/assistito`, {
      observe: 'response',
    });
  }
}
