import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-screening-attenzione',
  templateUrl: './modal-screening-attenzione.component.html',
  styleUrls: ['./modal-screening-attenzione.component.scss'],
})
export class ModalScreeningAttenzioneComponent {
  public messagge: string = '';
  public redirectTo: string | undefined;
  public pref: string | undefined;

  constructor(public modal: NgbActiveModal, private router: Router) {}

  closeModal(): void {
    this.modal.dismiss();
    if (this.redirectTo) {
      this.router.navigate(['/area-personale/gestisci-consensi/consensi-screening'], {
        state: {
          redirectTo: this.redirectTo,
          consenso: undefined,
        },
        replaceUrl: true,
      });
    }
    if (this.pref) {
      window.location.reload();
    }
  }
}
