import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-errore-guardia',
  templateUrl: './modal-errore.component.html',
  styleUrls: ['./modal-errore.component.scss'],
})
export class ModalErroreGuardiaComponent {
  title?: string;
  message?: string;
  loader?: boolean;

  constructor(public modal: NgbActiveModal, private router: Router) {}

  closeModalErrore(): void {
    if (window.location.pathname === '/login') {
      this.router.navigateByUrl('/');
    }
    this.modal.close('conferma');
  }
}
