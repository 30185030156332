import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Constants } from '@app/@shared/constants';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Observable, concatMap, map, of } from 'rxjs';
import { ConsensoScreeningService } from './consenso-screening.service';
import { ModalScreeningAttenzioneComponent } from './modal-screening-attenzione/modal-screening-attenzione.component';
import { PatientStatusGuard } from '@app/microservizi/screening/patient-status.guard';

@Injectable({
  providedIn: 'root',
})
export class ConsensoScreeningGuard implements CanActivate {
  public modalRef: NgbModalRef | null = null;

  constructor(
    private consensoScreeningService: ConsensoScreeningService,
    private patientStatusGuard: PatientStatusGuard,
    private modalService: NgbModal
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.patientStatusGuard.canActivate(route, state).pipe(
      concatMap((patientStatusValid) => {
        if (patientStatusValid) {
          return this.consensoScreeningService.getConsensoPaziente().pipe(
            map((consensiScreening) => {
              if (!consensiScreening.value) {
                this.openModale(state.url);
                return false;
              }
              return true;
            })
          );
        }
        return of(false);
      })
    );
  }

  openModale(redirectTo: string): void {
    this.modalRef = this.modalService.open(ModalScreeningAttenzioneComponent, { centered: true, size: 'lg' });
    this.modalRef.componentInstance.messagge = Constants.STRINGA_RICHIESTA_CONSENSO + 'Screening oncologici';
    this.modalRef.componentInstance.redirectTo = redirectTo;
  }
}
