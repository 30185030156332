import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { requiresAccessToken } from '@app/auth';
import { Observable, mergeMap } from 'rxjs';
import { AccessoFacilitatoService } from '../accesso-facilitato/accesso-facilitato.service';
import { Errore } from '@app/modello/errore';

@Injectable()
export class AccessoFacilitatoAccessTokenInterceptor implements HttpInterceptor {
  constructor(private accessoFacilitatoService: AccessoFacilitatoService, private router: Router) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (requiresAccessToken(request.url) && this.accessoFacilitatoService.isAccessoFacilitato()) {
      return this.accessoFacilitatoService.getValidAccessToken().pipe(
        mergeMap((token) => {
          if (token) {
            const header = 'Bearer ' + token;
            const headers = request.headers.set('Authorization', header);
            const clonedRequest = request.clone({ headers });
            return next.handle(clonedRequest);
          }
          throw new Errore('Spiacente, la sessione è scaduta. Effettua nuovamente il login.');
        })
      );
    }
    return next.handle(request);
  }
}
