import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Logger } from '@app/@shared';
import { Credenziali } from '@app/modello/credenziali';
import { OAuthService } from 'angular-oauth2-oidc';
import { BehaviorSubject, Observable, shareReplay } from 'rxjs';
import { AuthenticationService } from './authentication.service';
import { AccessoFacilitatoService } from './accesso-facilitato/accesso-facilitato.service';

/**
 * Provides storage for authentication credentials.
 * The Credentials interface should be replaced with proper implementation.
 */
@Injectable({
  providedIn: 'root',
})
export class CredentialsService {
  private logger = new Logger('CredentialsService');
  private subjectCredenziali = new BehaviorSubject<Credenziali | null>(this.extractUserCredentials());
  private credentials$ = this.subjectCredenziali.asObservable().pipe(shareReplay(1));

  constructor(
    private oauthService: OAuthService,
    private activatedRoute: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private accessoFacilitatoService: AccessoFacilitatoService
  ) {
    this.authenticationService.getEvents().subscribe((event) => {
      if (event.type === 'LOGOUT') {
        if (this.subjectCredenziali.value) {
          this.subjectCredenziali.next(null);
        }
      } else if (event.type === 'LOGIN') {
        const credenziali = this.extractUserCredentials();
        if (this.subjectCredenziali.value?.tax_code !== credenziali?.tax_code) {
          this.subjectCredenziali.next(credenziali);
        }
      } else {
        // Non gestito
      }
    });
  }

  /**
   * Gets the user credentials.
   * @return The user credentials or null if the user is not authenticated.
   */
  getCredentials(): Observable<Credenziali | null> {
    return this.credentials$;
  }

  getUserCreadentials(): Credenziali | null {
    return this.subjectCredenziali.value;
  }

  getCodiceFiscale(): string | undefined {
    return this.getUserCreadentials()?.tax_code;
  }

  private extractUserCredentials(): Credenziali | null {
    let credenziali: Credenziali | null = null;
    if (this.authenticationService.isAccessoFacilitato()) {
      credenziali = this.accessoFacilitatoService.getIdentityClaims();
    } else if (this.authenticationService.isAutenticatoConSisir()) {
      this.logger.debug(JSON.stringify(this.oauthService.getIdentityClaims()));
      credenziali = this.oauthService.getIdentityClaims() as Credenziali;
    } else {
      this.logger.warn('Impossibile determinare la tipologia di accesso');
    }
    if (credenziali?.tax_code) {
      let gender = 'f';
      if (credenziali.tax_code && credenziali.tax_code.length > 9 && Number(credenziali.tax_code[9]) < 4) {
        gender = 'm';
      }
      credenziali.avatar = 'assets/avatar/' + gender + '-patient.svg';
      return credenziali;
    } else if (credenziali !== null) {
      this.logger.warn('Utente senza codice fiscale', credenziali);
    } else {
      // Else if solo per logger
    }
    return null;
  }
}
