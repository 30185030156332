import { CommonModule, LocationStrategy } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { ProfileService } from '@app/@shared/profiles/profile.service';
import { AccessoFacilitatoAccessTokenInterceptor } from '@app/auth/interceptors/accesso-facilitato-access-token.interceptor';
import { I18nModule } from '@app/i18n';
import { environment } from '@env/environment';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { OAuthModule, OAuthService } from 'angular-oauth2-oidc';
import { concatMap, firstValueFrom } from 'rxjs';
import { AuthRoutingModule } from './auth-routing.module';
import { LoginComponent } from './login.component';
import { QueryStringAccessTokenInterceptor } from './interceptors/query-string-access-token.interceptor';

const protectedUrls = ['/api'];

export function requiresAccessToken(url: string): boolean {
  return !!protectedUrls.find((u) => url.toLowerCase().startsWith(u.toLowerCase()));
}

export function initializeOidc(
  profileService: ProfileService,
  oauthService: OAuthService,
  locationStrategy: LocationStrategy
): () => Promise<any> {
  return () =>
    firstValueFrom(
      profileService.getProfileInfo().pipe(
        concatMap((profilo) => {
          const iframe = window.self !== window.top;
          const config = {
            issuer: profilo.oidcIssuer,
            redirectUri: window.location.origin + locationStrategy.prepareExternalUrl('/login'),
            clientId: 'servizi.salute.basilicata.it',
            responseType: 'code',
            scope: 'openid profile email',
            sessionChecksEnabled: !iframe,
            oidc: true,
            showDebugInformation: !environment.production,
            clearHashAfterLogin: false,
          };
          oauthService.configure(config);
          if (!iframe) {
            oauthService.setupAutomaticSilentRefresh();
          }
          return oauthService.loadDiscoveryDocument();
        })
      )
    );
}

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    NgbModule,
    I18nModule,
    OAuthModule.forRoot({
      resourceServer: {
        sendAccessToken: true,
        customUrlValidation: (url) => {
          return requiresAccessToken(url) && !window.location.search.match(/accessToken=/g);
        },
      },
    }),
    AuthRoutingModule,
    HttpClientModule,
  ],
  declarations: [LoginComponent],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: (profileService: ProfileService, oauthService: OAuthService, locationStrategy: LocationStrategy) =>
        initializeOidc(profileService, oauthService, locationStrategy),
      deps: [ProfileService, OAuthService, LocationStrategy],
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: QueryStringAccessTokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AccessoFacilitatoAccessTokenInterceptor, multi: true },
  ],
})
export class AuthModule {}
