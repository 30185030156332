<app-header></app-header>
<section class="section-area error-404">
  <div class="container">
    <div class="inner-content">
      <h2 class="error-title">
        4
        <p></p>
        4
      </h2>
      <h3 class="error-text">Impossibile trovare la Pagina che stavi cercando.</h3>
      <p>
        La pagina che stai cercando potrebbe essere stata rimossa, il suo nome è cambiato o è temporaneamente non
        disponibile.
      </p>
      <div *ngIf="saluteBasilicataUrl$ | async as saluteBasilicataUrl">
        <a href="/home" class="btn btn-primary">Torna ai servizi</a>
      </div>
    </div>
  </div>
</section>
<app-footer></app-footer>
