export class Constants {
  static PAGE_SIZE = 5;

  static DATE_FORMAT = 'YYYY-MM-DD';
  static DATE_TIME_FORMAT = 'YYYY-MM-DDTHH:mm';

  static MEDICO_OSPEDALIERO = 'MEDICO_OSPEDALIERO';
  static OPERATORE = 'OPERATORE';
  static MEDICO_MMG = 'MEDICO_MMG';

  static SPIDLIV3 = 'SPIDLIV3';
  static SPIDLIV2 = 'SPIDLIV2';
  static CNS = 'CNS';

  static FSE = 'fascicolo';
  static SCREENING = 'screening';
  static PRENOTAZIONE = 'prenotazione';
  static PORTALE_ANAGRAFE = 'portale-anagrafe';
  static MEDICINA_DI_BASE = 'medicina-di-base';
  static UTILITA = 'utilita';
  static PER_LA_MAMMA = 'per-la-mamma';
  static CARTELLA_CLINICA_VACCINALE = 'cartella-clinica-vaccinale';
  static TACCUINO = 'taccuino';
  static PATIENT_SUMMARY = 'patient-summary';
  static LISTE_DI_ATTESA = 'liste-di-attesa';
  static AREA_PERSONALE = 'area-personale';

  static STRINGA_RICHIESTA_CONSENSO =
    "Per poter procedere è necessario prendere visione dell'Informativa sulla privacy del servizio ";
  static STRINGA_RICHIESTA_CONSENSO_FSE =
    'Per garantirti un percorso di cura più sicuro ed appropriato, fornisci il consenso alla consultazione dei dati e dei documenti del tuo FSE da parte dei professionisti sanitari';
  static STRINGA_SCREENING_ERROR_44 =
    "Il tuo profilo non è presente nell'anagrafica di screening della Regione Basilicata. Per ulteriori informazioni chiama <a href='tel:800054242'>800 054242 </a> (da rete fissa) <a href='tel:0971 441523'>0971 441523</a> o <a href='tel:0971 309299'>0971 309299</a>";
  static STRINGA_SCREENING_ERROR_43 =
    "Il servizio è disponibile solo per i residenti della Regione Basilicata. Per ulteriori informazioni chiama <a href='tel:800054242'>800 054242 </a> (da rete fissa) <a href='tel:0971 441523'>0971 441523</a> o <a href='tel:0971 309299'>0971 309299</a>";
  static STRINGA_SCREENING_ERROR_41 =
    "Non sono in programma appuntamenti dal momento che non rientri in nessun percorso di screening. Per ulteriori informazioni vai a <a class='text-decoration-underline' href='/screening/aderisci-campagna'>Aderisci alla Campagna</a>";

  static Keys = {
    Fse: {
      DELEGANTE: 'fse_delegante',
      ID_DELEGA: 'fse_id_delega',
      CONSENSI: 'fse_consensi',
      UTENTE_NON_CENSITO: 'fse_utente_non_censito',
      TIPI_DOCUMENTO: 'fse_tipi_documento',
    },
    Cup: {
      PAZIENTE: 'cup_paziente',
      DISPONIBILITA_SELEZIONATA: 'cup_disponibilita_selezionata',
      TUTTE_LE_DISPONIBILITA_ENTE: 'cup_tutte_disponibilita_ente',
      PRESIDI_OSPEDALIERI_ENTE: 'cup_presidi_ospedalieri_ente',
      INFO_RICETTA: 'cup_info_ricetta',
      NUMERO_NRE: 'cup_nre',
      PRIMA_DISPONIBILITA: 'cup_prima_disponibilita',
      CONFERMA_APPUNTAMENTO: 'cup_conferma_apputamento',
    },
    Screening: {
      STATO_PAZIENTE: 'screening_patient_status',
      MODALE_CONSENSO: 'screening_modale_consenso',
      MODALE_PREFERENZE: 'screening_modale_preferenze',
      CONSENSI: 'screening_consensi',
    },
  };
}
