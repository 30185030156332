import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Logger } from '@shared';
import { AuthenticationService } from './authentication.service';
import { OAuthService } from 'angular-oauth2-oidc';

const log = new Logger('AuthenticationGuard');

@Injectable({
  providedIn: 'root',
})
export class AuthenticationGuard implements CanActivate {
  constructor(private router: Router, private authService: AuthenticationService, private oauthService: OAuthService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.authService.isAutenticatoConSisir()) {
      this.authService.handleLogin();
      return true;
    }
    log.debug('Not authenticated, redirecting and adding redirect url...');
    this.authService.loginSisir(state.url);
    return false;
  }
}
