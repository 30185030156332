import { Component, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { ProfileService } from '@app/@shared/profiles/profile.service';
import { CredentialsService } from '@app/auth';
import { AuthenticationService } from '@app/auth/authentication.service';
import { Credenziali } from '@app/modello/credenziali';
import { IPaziente } from '@app/modello/prestazioni/paziente';
import { map } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  credentials: Credenziali | null = null;
  menuHidden = true;
  navbarfixed: boolean = false;
  accessoFacilitatoLogin: boolean = false;
  pazienteLoginCup: IPaziente | null = null;
  saluteBasilicataUrl$;
  delegante: Credenziali | null = null;

  constructor(
    profileService: ProfileService,
    private credentialsService: CredentialsService,
    private authenticationService: AuthenticationService,
    private router: Router
  ) {
    this.saluteBasilicataUrl$ = profileService.getProfileInfo().pipe(map((profilo) => profilo.indirizzoPortale));
    this.credentialsService.getCredentials().subscribe((utente) => {
      this.credentials = utente;
      this.accessoFacilitatoLogin = this.authenticationService.isAccessoFacilitato();
    });
  }

  toggleMenu(): void {
    this.menuHidden = !this.menuHidden;
  }

  logout(): void {
    this.authenticationService.logout();
  }

  @HostListener('window:scroll', ['$event']) onscroll(): void {
    if (window.scrollY > 0) {
      this.navbarfixed = true;
    } else {
      this.navbarfixed = false;
    }
  }
}
