import { DatePipe, registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import it from '@angular/common/locales/it';
import { APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy, Router, RouterModule } from '@angular/router';
import { AuthModule } from '@app/auth';
import { NgbDateParserFormatter, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { ErrorHandlerInterceptor, RouteReusableStrategy, SharedModule } from '@shared';
import { NgbDateParserFormatterIta } from './@shared/ngb-date-parser-formatter-ita/NgbDateParserFormatterIta';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeModule } from './home/home.module';
import { ShellModule } from './shell/shell.module';
//import { MatIconModule } from '@angular/material/icon';
//MOCK
import { MockAuthenticationService as AuthenticationService } from '@app/auth/authentication.service.mock';
import { MockCredentialsService as CredentialsService } from '@app/auth/credentials.service.mock';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
//ENDMOCK
import * as Sentry from '@sentry/angular-ivy';
import { CacheStorageInterceptor } from './@shared/interceptors/cache-storage.interceptor';
import { ModalAttenzioneComponent } from './microservizi/area-personale/gestisci-deleghe/deleghe-fse/modal-attenzione/modal-attenzione.component';

registerLocaleData(it);

@NgModule({
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    FormsModule,
    HttpClientModule,
    RouterModule,
    TranslateModule.forRoot(),
    NgbModule,
    SharedModule,
    ShellModule,
    HomeModule,
    AuthModule,
    AppRoutingModule, // must be imported as the last module as it contains the fallback route
  ],
  declarations: [AppComponent, ModalAttenzioneComponent],
  providers: [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {
        //comment
      },
      deps: [Sentry.TraceService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorHandlerInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CacheStorageInterceptor,
      multi: true,
    },
    {
      provide: RouteReuseStrategy,
      useClass: RouteReusableStrategy,
    },
    {
      provide: LOCALE_ID,
      useValue: 'it-IT',
    },
    {
      provide: DatePipe,
      useClass: DatePipe,
    },
    {
      provide: NgbDateParserFormatter,
      useClass: NgbDateParserFormatterIta,
    },
    //MOCK
    {
      provide: AuthenticationService,
      useClass: AuthenticationService,
    },
    {
      provide: CredentialsService,
      useClass: CredentialsService,
    },
    //ENDMOCK
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
