import { Component } from '@angular/core';
import { DeleganteServiceService } from '@app/microservizi/fse/delegante-service.service';
import { IPersona } from '@app/modello/persona';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss'],
})
export class ShellComponent {
  public delegante$: Observable<IPersona | null>;
  accessoFacilitato?: boolean;

  constructor(private deleganteService: DeleganteServiceService) {
    this.delegante$ = deleganteService.delegante$;
  }
}
