import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, switchMap, take } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { requiresAccessToken } from '../auth.module';

@Injectable()
export class QueryStringAccessTokenInterceptor implements HttpInterceptor {
  constructor(private activatedRoute: ActivatedRoute) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (requiresAccessToken(request.url)) {
      return this.activatedRoute.queryParams.pipe(
        take(1),
        switchMap((params) => {
          const accessToken = params['accessToken'];
          if (accessToken) {
            const header = 'Bearer ' + accessToken;
            const headers = request.headers.set('Authorization', header);
            request = request.clone({ headers });
          }
          return next.handle(request);
        })
      );
    }
    return next.handle(request);
  }
}
