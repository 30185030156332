export class Errore extends Error {
  public error: {
    detail: string;
  };

  constructor(detail: string) {
    super();
    this.error = {
      detail,
    };
  }
}
