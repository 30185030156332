/*
 * Entry point of the application.
 * Only platform bootstrapping code should be here.
 * For app-specific initialization, use `app/app.component.ts`.
 */

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from '@app/app.module';
import { environment } from '@env/environment';
import * as Sentry from '@sentry/angular-ivy';

async function startSentry(): Promise<void> {
  try {
    if (location.hostname.endsWith('.it')) {
      const response = await fetch('/management/sentry');
      const config = await response.json();
      config.integrations = config.integrations.map((integration) => {
        return Sentry[integration]();
      });
      Sentry.init(config);
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
}

startSentry().then(() => {
  if (environment.production) {
    enableProdMode();
  }

  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    // eslint-disable-next-line no-console
    .catch((err) => console.error(err));
});
