import { Component } from '@angular/core';
import { ProfileService } from '@app/@shared/profiles/profile.service';
import { environment } from '@env/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  public indirizzoPortale?: string;
  today = new Date();
  version = environment.version;

  constructor(private servizioInfo: ProfileService) {
    this.servizioInfo.getProfileInfo().subscribe((info) => (this.indirizzoPortale = info.indirizzoPortale));
  }

  isMobile(): boolean {
    return window.outerWidth < 768;
  }
}
