<div class="modal-alert">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{ title }}</h4>
    <a *ngIf="!loader" type="button" aria-label="Close" (click)="closeModalErrore()">
      <i class="bi bi-x-lg icona-sm icona-secondary"></i>
    </a>
  </div>
  <div class="modal-body d-flex" *ngIf="!loader">
    <div class="col-8" [innerHtml]="message"></div>
    <div class="col-4 ms-3 d-none d-sm-flex align-items-center">
      <div class="icona bg-orange-svg w-100 d-flex justify-content-center">
        <i class="bi bi-exclamation-triangle icona-lg"></i>
      </div>
    </div>
  </div>
  <div class="modal-footer row m-0 p-0">
    <a *ngIf="!loader" type="button" class="btn btn-primary col-sm-auto col-12" (click)="closeModalErrore()">OK</a>
    <app-loader *ngIf="loader && !message" class="loader my-3"></app-loader>
  </div>
</div>
