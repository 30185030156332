import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AuthenticationGuard, CredentialsService } from '@app/auth';
import { Observable } from 'rxjs';
import { PatientStatusService } from '../../screening/patient-status.service';

@Injectable({
  providedIn: 'root',
})
export class SceltaRuoloGuard implements CanActivate {
  constructor(
    private authGuard: AuthenticationGuard,
    private patientStatusService: PatientStatusService,
    private router: Router,
    private credentialService: CredentialsService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.authGuard.canActivate(route, state)) {
      const ruolo = sessionStorage.getItem('ruolo');
      const ruoli = this.credentialService.getUserCreadentials()?.resource_access;
      if (ruoli?.fse?.roles && ruoli.fse.roles.length > 0) {
        if (state.url !== 'fascicolo/scelta-ruolo' && (ruolo === '' || !ruolo)) {
          this.router.navigate(['/fascicolo/scelta-ruolo'], {
            state: {
              redirectTo: state.url,
            },
            replaceUrl: true,
          });
          return false;
        } else {
          return true;
        }
      } else {
        sessionStorage.setItem('ruolo', 'assistito');
        return true;
      }
    }
    return false;
  }
}
