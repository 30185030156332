import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-scelta-asl',
  templateUrl: './modal-scelta-asl.component.html',
  styleUrls: ['./modal-scelta-asl.component.scss'],
})
export class ModalSceltaAslComponent {
  constructor(public modal: NgbActiveModal, public router: Router, public route: ActivatedRoute) {}

  closeModal(): void {
    this.modal.dismiss();
  }
}
