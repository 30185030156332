import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from '../authentication.service';

@Component({
  templateUrl: './modal-utente-non-censito.component.html',
})
export class ModalUtenteNonCensitoComponent {
  constructor(public modal: NgbActiveModal, private authService: AuthenticationService) {}

  close(): void {
    this.modal.dismiss();
    // this.authService.logout();
  }
}
