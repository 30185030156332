import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ProfileService } from '@app/@shared/profiles/profile.service';
import { ITokenResponse } from '@app/modello/token-response';
import * as moment from 'moment';
import { Observable, catchError, map, mergeMap, of, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
// NON UTILIZZARE DIRETTAMENTE IL SERVIZIO ma usare AuthenticationService
export class AccessoFacilitatoService {
  constructor(private http: HttpClient, private profileService: ProfileService) {}

  login(accessoFacilitato: {
    tesseraSanitaria: string;
    codiceFiscale: string;
  }): Observable<HttpResponse<ITokenResponse>> {
    return this.profileService.getProfileInfo().pipe(
      mergeMap((profile) => {
        const baseUrl = profile.accessoFacilitatoJwtIssuer ?? '';
        const req = `password=${accessoFacilitato.tesseraSanitaria}&username=${accessoFacilitato.codiceFiscale}&grant_type=password`;
        return this.http
          .post<ITokenResponse>(`${baseUrl}/protocol/openid-connect/token`, req, {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
              Authorization: 'Basic ' + btoa('servizi.salute.basilicata.it:'),
            },
            observe: 'response',
          })
          .pipe(
            tap((res) => {
              this.salvaTokenInSessione(res.body!);
            })
          );
      })
    );
  }

  refreshToken(): Observable<HttpResponse<ITokenResponse>> {
    return this.profileService.getProfileInfo().pipe(
      mergeMap((profile) => {
        const baseUrl = profile.accessoFacilitatoJwtIssuer ?? '';
        const url = `${baseUrl}/protocol/openid-connect/token`;
        const headers = new HttpHeaders({
          Authorization: 'Basic ' + btoa('servizi.salute.basilicata.it:'),
          'Content-Type': 'application/x-www-form-urlencoded',
        });
        const body = new URLSearchParams();
        body.set('grant_type', 'refresh_token');
        body.set('client_id', 'servizi.salute.basilicata.it');
        body.set('refresh_token', sessionStorage.getItem('cup_refresh_token') ?? '');
        return this.http
          .post<ITokenResponse>(url, body.toString(), { headers, observe: 'response' })
          .pipe(tap((res) => this.salvaTokenInSessione(res.body!)));
      })
    );
  }

  isAccessoFacilitato(): boolean {
    return sessionStorage.getItem('cup_access_token') != null;
  }

  // TODO manca la chimata al logout
  logout(): Observable<boolean> {
    this.cleanSession();
    return of(true);
  }

  getIdentityClaims(): any {
    return JSON.parse(atob(sessionStorage.getItem('cup_access_token')!.split('.')[1]));
  }

  getValidAccessToken(): Observable<string | null> {
    const token = sessionStorage.getItem('cup_access_token');
    if (token) {
      const expiration = sessionStorage.getItem('cup_expires_at');
      if (expiration) {
        if (moment(Number(expiration)).isAfter(moment())) {
          return of(token);
        } else {
          const refresExpiration = sessionStorage.getItem('cup_refresh_expires_at');
          if (refresExpiration && moment(Number(refresExpiration)).isAfter(moment())) {
            return this.refreshToken().pipe(
              map(() => sessionStorage.getItem('cup_access_token')),
              catchError(() => {
                return of(null);
              })
            );
          }
        }
      }
    }
    this.cleanSession();
    return of(null);
  }

  private salvaTokenInSessione(token: ITokenResponse): void {
    sessionStorage.setItem('cup_access_token', token.access_token);
    sessionStorage.setItem('cup_refresh_token', token.refresh_token);
    sessionStorage.setItem('cup_granted_scopes', token.scope);
    const accessTokenExpirationDate: number = new Date().getTime() + Number(token.expires_in) * 1000 - 30_000;
    sessionStorage.setItem('cup_expires_at', `${accessTokenExpirationDate}`);
    const refreshTokenExpirationDate: number = new Date().getTime() + Number(token.refresh_expires_in) * 1000 - 30_000;
    sessionStorage.setItem('cup_refresh_expires_at', `${refreshTokenExpirationDate}`);
    sessionStorage.setItem('cup_session_state', token.session_state);
  }

  private cleanSession(): void {
    sessionStorage.removeItem('cup_access_token');
    sessionStorage.removeItem('cup_refresh_token');
    sessionStorage.removeItem('cup_granted_scopes');
    sessionStorage.removeItem('cup_expires_at');
    sessionStorage.removeItem('cup_refresh_expires_at');
    sessionStorage.removeItem('cup_session_state');
  }
}
