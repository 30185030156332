import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Constants } from '@app/@shared/constants';
import { AuthenticationGuard } from '@app/auth';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Observable, catchError, finalize, first, interval, map, of } from 'rxjs';
import { PatientStatusService } from './patient-status.service';
import { ModalErroreGuardiaComponent } from '@app/@shared/modal-errore/modal-errore.component';

@Injectable({
  providedIn: 'root',
})
export class PatientStatusGuard implements CanActivate {
  constructor(
    private authGuard: AuthenticationGuard,
    private patientStatusService: PatientStatusService,
    private modalService: NgbModal
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    if (this.authGuard.canActivate(route, state)) {
      let modalRefLoader: NgbModalRef | null = null;
      let patientStatusCompleted = false;
      const patientStatus$ = this.patientStatusService.find().pipe(
        finalize(() => {
          patientStatusCompleted = true;
          modalRefLoader?.dismiss();
        }),
        first(),
        map((patientStatus) => {
          let errore = '';
          if (patientStatus?.statusCode === '44') {
            errore = Constants.STRINGA_SCREENING_ERROR_44;
            const modalRef = this.modalService.open(ModalErroreGuardiaComponent, { centered: true, size: 'lg' });
            modalRef.componentInstance.title = 'Attenzione';
            modalRef.componentInstance.message = errore;
            modalRef.componentInstance.loader = false;
            return false;
          } else if (patientStatus?.statusCode === '43') {
            errore = Constants.STRINGA_SCREENING_ERROR_43;
            const modalRef = this.modalService.open(ModalErroreGuardiaComponent, { centered: true, size: 'lg' });
            modalRef.componentInstance.title = 'Attenzione';
            modalRef.componentInstance.message = errore;
            modalRef.componentInstance.loader = false;
            return false;
          } else {
            return true;
          }
        }),
        catchError(() => {
          const modalRef = this.modalService.open(ModalErroreGuardiaComponent, { centered: true, size: 'lg' });
          modalRef.componentInstance.title = 'Attenzione';
          modalRef.componentInstance.message =
            'Spiacenti, si è verificato un errore.\n Per ulteriori informazioni chiama:\n -800054242 (da rete fissa)\n -0971441523';
          modalRef.componentInstance.loader = false;
          return of(false);
        })
      );
      // Aspetta X ms prima di mostrare la modale così da evitare di mostrarla se il patientstatus è in cache
      interval(200)
        .pipe(first())
        .subscribe(() => {
          if (!patientStatusCompleted) {
            modalRefLoader = this.modalService.open(ModalErroreGuardiaComponent, {
              centered: true,
              size: 'md',
              backdrop: 'static',
              keyboard: false,
            });
            modalRefLoader.componentInstance.loader = true;
          }
        });
      return patientStatus$;
    }
    return of(false);
  }
}
