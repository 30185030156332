<div class="modal-maf">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">ERRORE DI ACCESSO</h4>
    <a type="button btn-add D.FLEX" aria-label="Close" (click)="close()">
      <i class="bi bi-x-lg icona-sm icona-secondary"></i>
    </a>
  </div>
  <div class="modal-body d-flex">
    <div class="col-12">
      <span>
        Impossibile accedere al Fascicolo Sanitario Elettronico della Regione Basilicata.<br />
        L'utente inserito non risulta residente presso la Regione Basilicata.
      </span>
    </div>
  </div>
  <div class="modal-footer d-flex col-12">
    <a type="button" class="btn btn-primary btn-lg w-25" (click)="close()"> OK </a>
  </div>
</div>
