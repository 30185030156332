import { Component } from '@angular/core';
import { environment } from '@env/environment';
import { ProfileService } from '../profiles/profile.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-redirect',
  template: '<app-loader [isLoading]="isLoading" loaderClass="full-page"></app-loader>',
})
export class RedirectPortaleComponent {
  isLoading = true;

  constructor(profileService: ProfileService, route: ActivatedRoute) {
    profileService.getProfileInfo().subscribe((profilo) => {
      if (environment.production) {
        window.location.replace(profilo.indirizzoPortale + route.snapshot.data['pathPortale']);
      }
    });
  }
}
