<div class="modal-alert">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Scegli la struttura sanitaria</h4>
    <a type="button" aria-label="Close" (click)="closeModal()">
      <i class="bi bi-x-lg icona-sm icona-secondary"></i>
    </a>
  </div>
  <div class="modal-body d-flex justify-content-center align-items-center autore">
    <a
      class="box d-flex flex-column"
      role="button"
      target="_blank"
      rel="noopener"
      href="https://sceltamedico.aspbasilicata.it:8443/sceltamedico/"
    >
      <i class="bi bi-person-plus icona-lg icona-secondary" title="Acccedi a scelta medico"></i>
      <span class="fs-5">ASP</span>
    </a>
    <a
      class="box d-flex flex-column"
      role="button"
      target="_blank"
      rel="noopener"
      href="https://sceltamedico.asmbasilicata.it:8443/sceltamedico"
    >
      <i class="bi bi-person-plus icona-lg icona-secondary" title="Acccedi a scelta medico"></i>
      <span class="fs-5">ASM</span>
    </a>
  </div>
</div>
