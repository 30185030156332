import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Route, UrlSegment, UrlTree } from '@angular/router';
import { Observable, map } from 'rxjs';
import { Logger } from './@shared';
import { ProfileService } from './@shared/profiles/profile.service';
import { AuthenticationService } from './auth';

@Injectable({
  providedIn: 'root',
})
export class AppRoutingGuard implements CanActivate {
  logger = new Logger('AppRoutingGuard');
  constructor(private servizioInfo: ProfileService, private authService: AuthenticationService) {
    this.authService.handleLogin();
  }

  canActivate(
    route: ActivatedRouteSnapshot
  ): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return this.servizioInfo.getProfileInfo().pipe(
      map((info) => {
        if (info.microservices != null && info.microservices.length > 0) {
          if (
            info.microservices.includes(route.url[0].path ?? '') ||
            route.url[0].path === 'fascicolo/scelta-ruolo' ||
            route.url[0].path === 'accesso-facilitato' ||
            route.url[0].path === 'scelta-autenticazione' ||
            route.url[0].path === 'home'
          ) {
            return true;
          } else {
            this.logger.error(
              'Errore info.microservices ' +
                JSON.stringify(info.microservices) +
                ' non ha trovato il path ' +
                route.url[0].path
            );
            return false;
          }
        } else {
          return true;
        }
      })
    );
  }

  canLoad(
    _route: Route,
    _segments: UrlSegment[]
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }
}
