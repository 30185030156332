import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { LoaderComponent } from './loader/loader.component';
import { RedirectPortaleComponent } from './redirect-portale/redirect-portale.component';
import { ModalErroreGuardiaComponent } from './modal-errore/modal-errore.component';
import { ModaleAnteprimaPdfComponent } from './modale-anteprima-pdf/modale-anteprima-pdf.component';
@NgModule({
  imports: [TranslateModule, CommonModule],
  declarations: [LoaderComponent, RedirectPortaleComponent, ModalErroreGuardiaComponent, ModaleAnteprimaPdfComponent],
  exports: [LoaderComponent, ModalErroreGuardiaComponent],
})
export class SharedModule {}
