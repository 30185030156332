<div class="d-flex flex-nowrap">
  <app-sidebar></app-sidebar>
  <div id="content" class="flex-grow-1 row m-0 p-0">
    <app-header class="p-0"></app-header>
    <div *ngIf="delegante$ | async as delegante" class="info-delegante">
      <div class="d-flex container align-items-center">
        <img
          class="avatar pt-3"
          [src]="'assets/avatar/' + (delegante.sex | lowercase) + '-patient.svg'"
          title="immagine profilo"
          alt="Immagine profilo"
        />
        <div class="text-info-delegante ms-3">
          Stai consultando il Fascicolo Sanitario Elettronico di:
          <br />
          {{ delegante.name | uppercase }} {{ delegante.surname | uppercase }}
        </div>
      </div>
    </div>
    <div class="main-page px-lg-1">
      <xng-breadcrumb class="breadcrumb container py-2"></xng-breadcrumb>
      <div class="container main-content">
        <router-outlet></router-outlet>
      </div>
    </div>
    <app-footer></app-footer>
  </div>
</div>
