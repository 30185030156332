<div *ngIf="autenticazione$ | async as autenticazione" class="login-container bg-light">
  <div class="login-box">
    <h1 translate>APP_NAME</h1>
    <div>
      <h6 class="d-inline-block">v{{ version }}</h6>
    </div>
    <div class="container">
      <div class="card col-md-6 mt-3 mx-auto">
        <div class="card-body">
          <h4 class="card-title text-center">
            <i class="far fa-3x fa-user-circle text-muted"></i>
          </h4>
          <div *ngIf="autenticazione; eq: false">
            Spiacenti, non è stato possibile completare l'accesso!
            <p>Si prega di riprovare più tardi. Se il problema persiste si invita a rivolgersi all’assistenza</p>
            <a href="{{ indirizzoPortale }}/assistenza/">link per proseguire su Assistenza</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
