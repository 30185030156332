import { Component } from '@angular/core';
import { UntilDestroy } from '@shared';
import { Observable } from 'rxjs';
import { AuthenticationService } from './authentication.service';
import { ProfileService } from '@app/@shared/profiles/profile.service';

@UntilDestroy()
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
  version: string | null = null;
  error: string | undefined;
  isLoading = false;
  autenticazione$: Observable<boolean>;
  public indirizzoPortale?: string;

  constructor(authenticationService: AuthenticationService, private servizioInfo: ProfileService) {
    this.autenticazione$ = authenticationService.handleLogin();
    this.servizioInfo.getProfileInfo().subscribe((info) => (this.indirizzoPortale = info.indirizzoPortale));
  }
}
